import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from 'reactstrap'
import {graphql} from 'gatsby'
import SEO from '../../components/seo'
import Layout from "../../containers/layout/layout"
import Header from '../../containers/layout/header'
import Footer from '../../containers/layout/footer'
import HeroArea from '../../containers/home/hero-area'
import Siderbar from '../../containers/global/sidebar'
import SectionTitle from '../../components/shared/section-title'
import Blog from '../../components/blog/layout-two'
import Pagination from '../../components/pagination'
import {MainWrapper, RecenPostWrap, RecentPostContent} from './blog-list.stc'
import {getRecentPostsFromEdges, getFeaturePostsFromEdges} from '../../utils/utilFunctions'

const BlogList = ({ data, pageContext, location, ...restProps }) => {
    const [author] = data.allContentfulPerson.edges;
    const {sectionTitleStyle, blogStyle} = restProps;
    const {currentPage, numberOfPages, categoriesSummary} = pageContext;

    const blogs = data.allContentfulBlogPost.edges.map( ({ node }) => {
        const dateSplit = node.date.split(" ");
        const {text: quote_text,author: quote_author} = node.quote !== null ? node.quote : { };

        return {
            node: {
                fields: {
                    slug: node.slug,
                    collection: 'contentfulPosts',
                    dateSlug: node.dateSlug
                },
                frontmatter: {
                    sortKey: node.sortKey,
                    date: node.date,
                    format: node.format,
                    title: node.title,
                    video_link: node.video_link,
                    quote_text: quote_text,
                    quote_author: quote_author,
                    link: node.link,
                    image: node.image,
                    images: node.images,
                    category: node.category,                    
                },
                excerpt: node.body.childMarkdownRemark.excerpt,
            }
        }
    });

    const featurePosts = getFeaturePostsFromEdges(data.featureContentfulBlogPost.edges);
    

    const recentFilesystemPosts = data.recentMarkdownRemark.edges;
    const recentContentfulPosts = data.recentContentfulBlogPost.edges;
    const recentPosts = getRecentPostsFromEdges(recentFilesystemPosts, recentContentfulPosts);

    return (
        <Layout>
            <SEO title={`Blog Page: ${currentPage}`} />
            <Header authorData={author.node} transparent/>
            <div className="main-content">
                <HeroArea featurePosts={featurePosts}/>
                <MainWrapper>
                    <Container>
                        <Row>
                            <Col lg={8}>
                            <RecenPostWrap>
                                <SectionTitle
                                    {...sectionTitleStyle}
                                    title="Most Recent Post"
                                />
                                <RecentPostContent>
                                    {blogs.map(blog => (
                                        <Blog
                                            {...blogStyle}
                                            key={blog.node.fields.slug}
                                            content={{
                                                ...blog.node.fields, 
                                                ...blog.node.frontmatter,
                                                excerpt: blog.node.excerpt
                                            }}
                                        />
                                    ))}
                                </RecentPostContent>
                                <Pagination
                                    rootPage="/"
                                    currentPage={currentPage}
                                    numberOfPages={numberOfPages}
                                />
                            </RecenPostWrap>
                            </Col>
                            <Col lg={4}>
                                <Siderbar categoriesSummary={categoriesSummary} recentPosts={recentPosts}/>
                            </Col>
                        </Row>
                    </Container>
                </MainWrapper>   
            </div>
            <Footer/>
        </Layout> 
    )
}
  
export const query = graphql `
    query BlogListQuery($skip: Int!, $limit: Int!){
        featureContentfulBlogPost: allContentfulBlogPost(
            filter: { is_featured : { eq: true } }
            sort: {order: DESC, fields: publishDate}
            limit: 4
        ) {
            edges {
                node {
                ...MajorContentfulBlogPostField
                body {
                    childMarkdownRemark {
                        excerpt(format: PLAIN, pruneLength: 85)
                    }
                }
                is_featured
                }
            }
        }
        recentMarkdownRemark: allMarkdownRemark(
            filter: { fields: { collection : { eq: "posts" } }, frontmatter: { format : { eq: "image" } } }
            sort: {order: DESC, fields: frontmatter___date}
            limit: 4
        ) {
            edges {
                node {
                    ...RecentMarkdownRemarkField
                }
            }
        }
        recentContentfulBlogPost: allContentfulBlogPost(
            filter: { format : { eq: "image" } }
            sort: {order: DESC, fields: publishDate}
            limit: 4
        ) {
            edges {
                node {
                    ...RecentContentfulBlogPostField
                }
            }
        }     
        allContentfulBlogPost(
            sort: {order: DESC, fields: publishDate}
            limit: $limit
            skip: $skip
        ) {
            totalCount
            edges {
                node {
                    slug
                    dateSlug
                    sortKey: publishDate(formatString: "yyyyMMDDHHmm")
                    date: publishDate(formatString: "LL")
                    format
                    title
                    video_link
                    quote {
                        text
                        author
                    }
                    link
                    image: heroImage {
                        sizes(maxWidth: 510, maxHeight: 560, quality: 100) {
                            ...GatsbyContentfulSizes_withWebp
                        }
                    }
                    images {
                        sizes(maxWidth: 510, maxHeight: 560, quality: 100) {
                            ...GatsbyContentfulSizes_withWebp
                        }
                    }
                    category
                    body {
                        childMarkdownRemark {
                            excerpt(format: PLAIN, pruneLength: 85)
                        }
                   }
                }
            }
        }
        allContentfulPerson(filter: { id: { eq: "801d0181-aa93-5da9-82f1-fae3b71554af" } }) {
            edges {
                node {
                    ...ContentfulPersonField
                }
            }
        }
    }
`;

BlogList.propTypes = {
    sectionTitleStyle: PropTypes.object,
    blogStyle: PropTypes.object
}

BlogList.defaultProps = {
    sectionTitleStyle: {
        mb: '46px'
    },
    blogStyle: {
        mb: '50px'
    }
}

export default BlogList
